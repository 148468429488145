import naja from "naja";

$(document).ready(function () {
	var page = new Page(this)
	page.initWebStaff();
	page.paginatorFakeLink();
});


var Page = function () {
	this.firstClick = true;
}

Page.prototype.initWebStaff = function () {
	$('.categories .has-children').on('mouseover', function () {
		if ($('.submenu-bg').length == 0) {
			$('body').prepend('<div class="submenu-bg"></div>');
		}
	});

	$('.categories .has-children').on('mouseleave', function () {
		$('.submenu-bg').remove();
	});

	$('.menu-toggle').on('click', function () {
		$(this).toggleClass('active');
		$('.menu').toggleClass('active');
	});

	$('.category.has-children .arrow').on('click', function (e) {
		e.preventDefault();
		let liCurrentEl = $(this).closest('li');
		let liList = liCurrentEl.closest('ul').find('li');
		if (!liCurrentEl.hasClass('active')) {
			liList.removeClass('active');
			liCurrentEl.addClass('active');
		} else {
			liCurrentEl.removeClass('active');
		}
	});
}

Page.prototype.paginatorFakeLink = function () {
	var self = this;
	$('.paginatorFakeLink').off('click').on('click', function (e) {
		e.preventDefault();

		naja.makeRequest('POST', $(this).attr('href'), {'do': $(this).data('link').split('=')[1]})
			.then((payload) => {
				if (self.firstClick) {
					self.autoPaginator();
					self.firstClick = false;
				}

				self.paginatorFakeLink();
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

Page.prototype.autoPaginator = function () {
	var self = this;
	var onAjax = false;

	$(window).scroll(function (event) {
		if ($('.paginatorFakeLink').offset().top < $(window).scrollTop() + 2000) {
			if (!onAjax) {
				onAjax = true;

				console.log('ajax');
				naja.makeRequest('POST', $('.paginatorFakeLink').attr('href'), {'do': $('.paginatorFakeLink').data('link').split('=')[1]})
					.then((payload) => {
						onAjax = false;
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	});
}
